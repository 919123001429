import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import Bowser from "bowser"; 

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useHttpClientNoHead } from '../../shared/hooks/http-hook-no-head';
import { AuthContext } from '../../shared/context/auth-context';
import './Auth.css';
import EventBranding from '../../shared/components/UIElements/EventBranding';

const Auth = props => {

  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const [invalidTicket, setInvalidTicket] = useState(false);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { sendARequest } = useHttpClientNoHead();
  const agentStats = Bowser.parse(window.navigator.userAgent)

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      duration: 3,
    },
    out: {
      opacity: 0,
      duration: 3,
    },
  };

  const goToRegister = () => {
    history.push('/register-ticket');
  }

  const submitHandler = async event => {
    event.preventDefault();

    const browserName = agentStats.browser.name
    const browserVerion = agentStats.browser.version
    const osName = agentStats.os.name
    const osVersion = agentStats.os.version
    const osVersionName = agentStats.os.versionName
    const platform = agentStats.platform.type
   

    setInvalidTicket(false);

    let ticketS1 = event.target.ticketS1.value.toUpperCase();
    let ticketS2 = event.target.ticketS2.value;
    let ticketS3 = event.target.ticketS3.value.toUpperCase();

    var city = '';
    var country = '';
    var countryCode = '';

    if (ticketS1 === '' || ticketS2 === '' || ticketS3 === '') {
      setInvalidTicket(true);
    } else {

      try {
        const response = await sendARequest(`https://ip.nf/me.json`, 'GET');
        city = response.ip.city
        country = response.ip.country
        countryCode = response.ip.country_code
       
      } catch (err) {
        console.log(err);
      }

      let ticketNo = ticketS1 + '-' + ticketS2 + '-' + ticketS3;
      ////console.log(ticketNo);
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/auth/`,
          'POST',
          JSON.stringify({
            email: event.target.email.value,
            ticketNo: ticketNo,
            browserName: browserName,
            browserVerion: browserVerion,
            osName: osName,
            osVersion: osVersion,
            osVersionName: osVersionName,
            platform: platform,
            city: city,
            country: country,
            countryCode: countryCode
          }),
          {
            'Content-Type': 'application/json',
            Authorization: auth.token,
          }
        );
        ////console.log(response);
        auth.login(response.userId, response.email, response.token);
        history.push('/');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Fragment>
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />

      <motion.div
        className="wrapper center col"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <div className="auth-wrapper">
          <EventBranding
            src="https://eventstreams.co/img/HANSA/event-logo.png"
            alt="client name"
            eventTitle="Clinical case studies with imlifidase in kidney transplantation"
          />

          {isLoading && (
            <div className="center">
              <LoadingSpinner asOverlay />
            </div>
          )}
          {!isLoading && (
            <form className="auth-form__wrapper" onSubmit={submitHandler}>
              <div className="input_wrapper">
                <span className="ticket-label-title">Email:</span>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email"
                  inputClassName="no-group form-padding"
                />
              </div>
              <div className="input_wrapper">
                <span className="ticket-label-title">Ticket No:</span>
                {invalidTicket && (
                  <span className="ticket-errors">
                    ticket seems to be invalid, please check.
                  </span>
                )}
                <div className="auth-ticket-wrapper">
                  <Input
                    id="ticketS1"
                    name="ticketS1"
                    type="text"
                    placeholder="ABC"
                    wrapperClassName="ticket-input-container"
                    inputClassName="no-group ticket-input"
                    maxLength={3}
                  />
                  <span className="ticket-marker">-</span>
                  <Input
                    id="ticketS2"
                    name="ticketS2"
                    type="text"
                    placeholder="123456"
                    wrapperClassName="ticket-input-container"
                    inputClassName="no-group ticket-input ticket-input-grow"
                    maxLength={6}
                  />
                  <span className="ticket-marker">-</span>
                  <Input
                    id="ticketS3"
                    name="ticketS3"
                    type="text"
                    placeholder="DEFG"
                    wrapperClassName="ticket-input-container"
                    inputClassName="no-group ticket-input"
                    maxLength={4}
                  />
                </div>
              </div>
              <Button
                type="submit"
                className="button-primary login-btn"
                title="Login"
              />
              
            </form>
          )}
          <div className='auth-register-container'>
            <h1 className='auth-register-text'>Not got a ticket?</h1>
            <Button className='button-secondary request-link' title='register' type='text' onClick={goToRegister} />
          </div>
        </div>
      </motion.div>
    </Fragment>
  );
};

export default Auth;
